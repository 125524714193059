<template>
  <div class="login">
    <div class="leftBj fl"></div>
    <div class="rightPart fl">
      <div class="title">欢迎登录</div>
      <el-form :rules="rules" ref="form" :model="form" label-width="0" style="width: 408px;margin: 5% auto 0">
        <el-form-item label="" prop="mobile">
          <el-input prefix-icon="el-icon-user-solid" v-model.trim="form.mobile" class="input_line_height_40" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password" style="margin-top: 32px">
          <el-input type="password" prefix-icon="el-icon-lock"  v-model.trim="form.password" class="input_line_height_40 " placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item label="" prop="checked" class="left" style="margin-top: 20px">
          <el-checkbox class="loginChecked fl" v-model="form.checked" >记住密码</el-checkbox>
          <span class="ft pointer" style="color: #2279f4 !important;">找回密码</span>
        </el-form-item>
        <el-button type="primary" style="margin-top: 5%;width: 408px;" @click="submit" :loading="loading">登 录</el-button>
      </el-form>
<!--      <div class="qrContent">-->
<!--        <div class="qrItem fl">-->
<!--          <img src="../assets/images/login/qr.png"/>-->
<!--          <br />-->
<!--          <img src="../assets/images/login/APP.png" class="app"/>-->
<!--          <p>移动APP下载</p>-->
<!--          <p>员工端</p>-->
<!--        </div>-->
<!--        <div class="qrItem fl" style="margin-left: 208px">-->
<!--          <img src="../assets/images/login/chezhuduanQr.jpg"/>-->
<!--          <br />-->
<!--          <img src="../assets/images/login/APP.png" class="app"/>-->
<!--          <p>移动APP下载</p>-->
<!--          <p>车主端</p>-->
<!--        </div>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  name: "Login",
  data(){
    return {
      rules:{
        mobile:[
          {required:true,message:'请填写手机号',trigger:'blur'}
        ],
        password:[
          {required:true,message:'请填写密码',trigger:'blur'}
        ]
      },
      form:{
        mobile:'',
        password:'',
        checked:''
      },
      loading:false
    }
  },
  methods:{
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = {
            mobile: this.form.mobile,
            password: md5(this.form.password)
          };
          this.loading = true;
          this.$axios.post('/sys-api/validateLogin', formData, (res) => {
            this.loading = false;
            if (res.code === '100') {
              let menuList = [
                {
                  name:'首页',
                  path:'/dashboard'
                }
              ]
              window.localStorage.setItem("userInfo",JSON.stringify(res.data));
              window.localStorage.setItem("menuList",JSON.stringify(menuList))
              this.$router.push("/dashboard");
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login{
  height: 100%;
  .leftBj{
    width: 67%;
    height: 100%;
    background:url("../assets/images/login/bj.jpg") no-repeat center;
    background-size: 100% 100%;
  }
  .rightPart{
    width: 33%;
    height: 100%;
    position: relative;
    .title{
      font-weight: bold;
      color: #000;
      font-size: 20px;
      text-align: center;
      margin-top: 14%;
    }
    .qrContent{
      width: 408px;
      margin: 0 -204px;
      position: absolute;
      left: 50%;
      bottom: 20px;
      .qrItem{
        width: 100px;
        img{
          height: 100px;
          width: 100px;
        }
        img.app{
          width: 40px;
          height: 60px;
          margin-left: 30px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
        p{
          text-align: center;
          color: #707070;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
